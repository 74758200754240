import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { GaChip } from "@/components/general/GaChip";

export function MailFeedback(props: { mailTitle: string; mailBody: string }): GaVueComponent {
  const { t } = useI18n();
  return (
    <a href={`mailto:feedback@getabstract.com?subject=${encodeURIComponent(props.mailTitle)}&body=${encodeURIComponent(props.mailBody)}`} target="_blank">
      <i class="ico-envelope"></i>
      {t("search:askGa.feedback.wantToGiveFeedback")}
    </a>
  );
}

export function GaFeedback(props: { title: string; value: boolean | null; showMailFeedbackLink?: boolean; onChange: (feedbackValue: boolean) => void; buttonBorder?: boolean; mailTitle: string; mailBody: string }): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class="d-grid gap-3">
      <h5 class="m-0">{props.title}</h5>
      <div class="getabstract-ai__feedback">
        <GaChip icon="ico-thumbs-up" variant="success" border={props.buttonBorder} type="radio" name="ga-feedback" changeHandler={() => props.onChange(true)} active={props.value === true}>
          {t("search:askGa.feedback.helpful")}
        </GaChip>
        <GaChip icon="ico-thumbs-down" variant="danger" border={props.buttonBorder} type="radio" name="ga-feedback" changeHandler={() => props.onChange(false)} active={props.value === false}>
          {t("search:askGa.feedback.notHelpful")}
        </GaChip>
        {props.showMailFeedbackLink === true ? <MailFeedback mailTitle={props.mailTitle} mailBody={props.mailBody} /> : null}
      </div>
    </div>
  );
}
