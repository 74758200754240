import type { SummaryMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { type TranslateFunction, useI18n } from "@/i18n/i18nSetup";
import { GaButton } from "@/components/general/GaButton";
import { Language } from "@utils/type/type";
import type { Store } from "@/common/storeUtils";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { addToast } from "@/components/general/GaToast";
import { useCustomModal } from "@/components/general/GaModal";
import { SummaryCardMini } from "@/components/cards/SummaryCardMini";

type ShareLink = {
  href: string;
  title: string;
};

function getShareLinks(t: TranslateFunction, dataId: number, summaryTitle: string | null, summaryDescription: string): ShareLink[] {
  const miabLink = window.location.origin + "/" + Language.getCurrentLanguage() + "/summary/" + dataId;
  const currentDate = new Date();
  const minutes = currentDate.getMinutes() < 31 ? 30 : 0;
  const description = encodeURIComponent(t("miab:addToCalendar.description", [summaryDescription, miabLink]));
  const title = encodeURIComponent(t("miab:addToCalendar.title", [summaryTitle ?? ""]));
  const formattedDate = `${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}T${currentDate.getHours()}${minutes}00Z`;
  const formattedOutlookDate = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}T${currentDate.getHours()}%3A${currentDate.getMinutes()}%3A00`;
  const googleLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formattedDate}&details=${description}&location=&trp=false`;
  const outlookLink = `https://outlook.office.com/calendar/0/action/compose?allday=false&body=${description}&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${formattedOutlookDate}&subject=${title}`;
  return [
    { href: outlookLink, title: "Outlook" },
    { href: googleLink, title: "Google" },
  ];
}

async function copyToClipboard(miabLink: string, successMessage: string): Promise<void> {
  await navigator.clipboard.writeText(miabLink);
  addToast({
    message: successMessage,
  });
}

async function openPopup(summary: SummaryMetadata, t: TranslateFunction): Promise<void> {
  const miabLink = new URL(window.location.origin + "/" + Language.getCurrentLanguage() + "/miab/" + summary.dataId);
  await useCustomModal()({
    body: () => (
      <div class="miab-shareModal">
        <div class="miab-shareModal__description">
          <h3>{t("miab:page.shareModal.title")}</h3>
          <p>{t("miab:page.shareModal.description")}</p>
        </div>
        <div class="miab-shareModal__summary">
          <SummaryCardMini summary={summary} link={miabLink} />
        </div>
        <div class="miab-shareModal__shareLink">
          <p class="caption">{t("miab:page.shareModal.linkCaption")}</p>
          <GaFormFieldInputText name="miabShareLink" value={miabLink.href} onUpdateValue={() => void 0} disabled={true} />
          <div class="miab-shareModal__shareLinkButton">
            <GaButton onClick={() => copyToClipboard(miabLink.href, t("miab:page.shareModal.copySuccessful"))}>{t("miab:page.shareModal.copyLink")}</GaButton>
          </div>
        </div>
      </div>
    ),
    size: "lg",
  });
}

export function Header(props: { summary: SummaryMetadata; showShareModalStore: Store<boolean> }): GaVueComponent {
  const { t } = useI18n();
  const links = getShareLinks(t, props.summary.dataId, props.summary.title, props.summary.descriptionHtml ?? "");
  return (
    <>
      <div class="miab-header__image">
        <img src={props.summary.coverUri} class="img-fluid miab-header__image-img" alt={props.summary.title + " cover"} />
      </div>
      <div class="miab-header__title">
        <div class="mb-1">
          <span class="badge miab-badge miab-badge--light text-uppercase">{t("miab:page.header.badge")}</span>
        </div>
        <h1 class="mb-4">{props.summary.title}</h1>
      </div>
      <div class="d-flex gap-3 flex-wrap miab-header__action">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="ico-calendar" />
            &nbsp;Schedule Group Discussion
          </button>
          <ul class="dropdown-menu">
            {links.map((shareLink) => (
              <li class="dropdown-item">
                <a href={shareLink.href} target="_blank">
                  {shareLink.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <GaButton onClick={() => openPopup(props.summary, t)}>
          <i class="ico-share-alt2" />
          &nbsp;Share
        </GaButton>
        <GaButton>
          <i class="ico-bookmark" />
          &nbsp;Save
        </GaButton>
        <GaButton variant="primary-alt">
          <i class="ico-download" />
          &nbsp;Download as PDF
        </GaButton>
      </div>
      <div class="miab-header__background"></div>
    </>
  );
}
