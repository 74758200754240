// @ts-ignore
import { gaApiCall } from "@utils/httpClient";
// @ts-ignore
import { renderJsonWithNumbersAsBigint, parseJsonWithNumbersAsBigint } from "@utils/json";
import * as schema from "@newgenerated/experimental/schema";
import * as mapper from "@newgenerated/experimental/mapper";
export async function getExperimentalModel(props: { experimentId: number; experimentQuery: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return renderJsonWithNumbersAsBigint(
        (() => {
          return value;
        })(),
      );
    })(props.experimentId),
  );
  path += "?";
  path += new URLSearchParams({
    experimentQuery: renderJsonWithNumbersAsBigint(
      ((value) => {
        return renderJsonWithNumbersAsBigint(
          (() => {
            return value;
          })(),
        );
      })(props.experimentQuery),
    ),
  }).toString();
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentModel } => {
    const parsed = mapper.deserialize_ExperimentModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function updateExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return renderJsonWithNumbersAsBigint(
        (() => {
          return value;
        })(),
      );
    })(props.experimentId),
  );
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentModel } => {
    const parsed = mapper.deserialize_ExperimentModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function createExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return renderJsonWithNumbersAsBigint(
        (() => {
          return value;
        })(),
      );
    })(props.experimentId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getExperimentalDemoModel(): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentalDemoModel } => {
    const parsed = mapper.deserialize_ExperimentalDemoModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function putExperimentalDemoModel(props: { body: schema.ExperimentalDemoModel }): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentalDemoModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentalDemoModel } => {
    const parsed = mapper.deserialize_ExperimentalDemoModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}
