import type {
  ActionableMetadata,
  AskGetabstractQuestion,
  AskGetabstractQuestionAnswers,
  ChannelMetadata,
  CustomPageListView,
  FeatureType,
  FormFilter,
  FormFilterName,
  FullSearchResults,
  SearchFiltersForm,
  SummaryMetadata,
} from "@newgenerated/shared/schema";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import type { ComponentSimpleInteractions, SimpleComponentUiState } from "@/components/ask-getabstract/utils/backgroundWorker";

export type FullSearchStoreApiMethods = {
  search: (searchFiltersForm: SearchFiltersForm) => Promise<FullSearchResults>;
  createQuestion: (question: AskGetabstractQuestion) => Promise<void>;
  getQuestionAnswers: (questionUuid: string) => Promise<AskGetabstractQuestionAnswers>;
  hasFeature: (feature: FeatureType) => Promise<boolean>;
};

export const defaultAmount: { [content in ContentTypes]: number } = {
  SUMMARY: 4,
  ACTIONABLE: 6,
  CHANNEL: 9,
  CUSTOMPAGE: 9,
};

export const contentTypeOrder: { [content in ContentTypes]: number } = {
  SUMMARY: 1,
  ACTIONABLE: 2,
  CHANNEL: 3,
  CUSTOMPAGE: 4,
};

export type FormFilterExtended = FormFilter & {
  isCollapsed: boolean;
  multiSelectProps: MultiSelectStateProps<string> | null;
};

export type ContentTypeDiscriminator = "SUMMARY" | "CHANNEL" | "CUSTOMPAGE" | "ACTIONABLE";

export type DataType =
  | {
      discriminator: "SUMMARY";
      items: SummaryMetadata[];
    }
  | {
      discriminator: "CHANNEL";
      items: ChannelMetadata[];
    }
  | {
      discriminator: "CUSTOMPAGE";
      items: CustomPageListView[];
    }
  | {
      discriminator: "ACTIONABLE";
      items: ActionableMetadata[];
    };
export type ContentTypeProps = DataType & {
  amountToShow: number;
  title: string;
  formFilters: FormFilterExtended[];
};

export type ExtendedContentType = ContentTypes | "ALL";
export type UpdateFilter = (filterName: FormFilterName, newActiveValues: string[], contentType: ContentTypeDiscriminator) => ContentTypeProps[];

export type UiState = {
  filtersExtended: boolean;
  multiSelectProps: MultiSelectStateProps<string>;
};

export type SearchAiComponentState =
  | { feature: "NOT_AVAILABLE" }
  | {
      feature: "AVAILABLE";
      uiState: SimpleComponentUiState;
      displayFullAnswer: boolean;
    };

export type SummariesPaging = {
  page: number;
  totalCount: bigint;
};

export type SearchStatus = "LOADING" | "LOADMORE" | "IDLE" | "ERROR";

export type SearchStoreState = UiState &
  ComponentSimpleInteractions & {
    contentTypeProps: ContentTypeProps[];
    selectedContentType: ExtendedContentType;
    status: SearchStatus;
    summariesPaging: SummariesPaging;
    params: URLSearchParams;
    aiState: SearchAiComponentState;
  };

export type SearchStoreActions = {
  search: (softReload?: boolean) => Promise<void>;
  selectContentType: (contentType: ExtendedContentType) => void;
  updateFilter: UpdateFilter;
  resetAllFilters: (contentType?: ContentTypeDiscriminator) => void;
  toggleFilters: () => void;
  loadMoreSummaries: () => Promise<void>;
  toggleSingleFilter: (filterName: FormFilterName, contentType: ContentTypeDiscriminator) => void;
  showFullAiAnswer: () => void;
  onMultiSelectSearchTermChange: (contentType: ContentTypeDiscriminator, formFilterName: FormFilterName, searchTerm: string) => void;
  toggleMultiSelect: (contentType: ContentTypeDiscriminator, formFilterName: FormFilterName) => void;
};
