/**
 * Shorthand plugin to track outbound-links.
 * Wired through <code>data-ga-outbound-link=<HUMAN-READABLE-TARGET-NAME></code>  attribute on <code>&lt;a&gt;</code> tags.
 * e.g. `<a href="/faq" data-ga-outbound-link="FAQ">`
 * If <code>data-ga-outbound-link-type</code> is set, the type of the outbound link can be specified, otherwise it will be tracked a GENERIC link.
 */

import { registerModule } from "@/Registry";
import type { OutboundLink } from "@generated/model/outboundLink";
import { OutboundLinkType } from "@generated/model/outboundLinkType";
import { getCsrfToken } from "@utils/csrfUtils";

const handleExternalLinkTracking = (element: Element): void => {
  if (!(element instanceof HTMLAnchorElement)) {
    return;
  }

  const analyticTag = document.querySelector("[data-ga-analytic]");
  if (analyticTag == null || !(analyticTag instanceof HTMLElement) || analyticTag.dataset.gaAnalytic === undefined) {
    return;
  }
  const meta = analyticTag.dataset.gaAnalytic;
  let clickIntercepted = false;

  element.addEventListener("click", async (e) => {
    if (clickIntercepted) {
      clickIntercepted = false;
      return;
    }

    if (element.target !== "_blank") {
      e.preventDefault();
    }

    let type = OutboundLinkType.getValues().find((t: string) => element.dataset.outboundLinkType === t);
    if (type === undefined) {
      type = "GENERIC";
    }

    const payload: OutboundLink = {
      id: null,
      meta: meta,
      type: type,
      targetName: element.dataset.gaOutboundLink ?? null,
      targetUrl: element.href,
    };

    await fetch("/gaapi/act/olc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-Token": await getCsrfToken(),
      },
      // eslint-disable-next-line no-restricted-syntax
      body: JSON.stringify(payload),
    }).finally(() => {
      clickIntercepted = true;
      if (element.target !== "_blank") {
        element.click();
      }
    });
  });
};

export function initOutboundLinkTracking(element: Element): void {
  handleExternalLinkTracking(element);
}

registerModule("[data-ga-outbound-link]", (e) => initOutboundLinkTracking(e));
