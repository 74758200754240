import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";
import type { ComponentInteractions, ComponentUiState } from "@/components/ask-getabstract/utils/store";
import { SelectedQuestion } from "@/components/ask-getabstract/uiFragments/SelectedQuestion";
import { AnswerCard } from "@/components/ask-getabstract/uiFragments/Answer";
import { Feedback } from "@/components/ask-getabstract/uiFragments/Feedback";
import { References } from "@/components/ask-getabstract/uiFragments/References";
import { RelatedActionables } from "@/components/ask-getabstract/uiFragments/RelatedActionables";
import { RelatedQuestions } from "@/components/ask-getabstract/uiFragments/RelatedQuestions";
import type { Store } from "@/common/storeUtils";

export function CurrentQuestionAnswer(props: { interactionsStore: Store<ComponentInteractions>; uiState: DeepReadonly<ComponentUiState> }): GaVueComponent | null {
  if (props.uiState.kind !== "IDLE" || props.uiState.value.current.status !== "SUCCESS") {
    return null;
  }

  const { current } = props.uiState.value;

  return (
    <div class="d-grid gap-5">
      <SelectedQuestion question={current.question} isRelatedQuestion={props.uiState.value.history.length > 0} detectedLanguageLabel={current.detectedLanguageLabel} />
      <AnswerCard answer={current.answer} contextDocs={current.contextDocs} questionUuid={current.questionUuid} />
      <Feedback feedbackByUuid={props.uiState.feedbackByUuid} feedbackStore={props.interactionsStore.sub("giveFeedback")} question={current.question} questionUuid={current.questionUuid} showMailFeedbackLink={true} />
      <References
        bookmarksByDataId={props.uiState.bookmarksByDataId}
        bookmarkInteractionsStore={props.interactionsStore.sub("toggleBookmarks")}
        contextDocs={current.contextDocs}
        answer={current.answer}
        questionUuid={current.questionUuid}
      />
      <RelatedActionables relatedActionables={current.relatedActionables} questionUuid={current.questionUuid} showExplanation={true} />
      <RelatedQuestions questionStore={props.interactionsStore.sub("question")} relatedQuestions={current.relatedQuestions} />
    </div>
  );
}
