import { createParentComponent } from "@/common/vueUtils";

type Variant = "primary" | "primary-dark" | "success" | "danger" | "warning" | "muted";
type GaBadgeProps = {
  variant: Variant;
  icon?: string;
  pill?: boolean;
  caption?: boolean;
};

export const GaBadge = createParentComponent<GaBadgeProps>((props) => {
  function getClasses(): string[] {
    const classes = ["badge", `badge-${props.variant}`];
    if (props.pill === true) {
      classes.push("rounded-pill");
    }
    if (props.caption === true) {
      classes.push("badge-caption");
    }
    return classes;
  }

  return (
    <span class={getClasses()}>
      {props.icon !== undefined ? <i class={props.icon} aria-hidden="true"></i> : null}
      {props.children}
    </span>
  );
});
