import type { GaVueComponent } from "@/common/vueUtils";
import { NoResults } from "@/components/search/NoResults";
import { useI18n } from "@/i18n/i18nSetup";
import { contentTypeOrder, type ContentTypeProps, type ExtendedContentType, type SearchStatus, type SearchStoreActions, type SummariesPaging } from "@/components/search/fullSearchStoreTypes";
import { ContentType } from "@/components/search/ContentType";
import type { DeepReadonly } from "vue";

function ErrorState(): GaVueComponent {
  const { t } = useI18n();
  return (
    <div>
      <h2 class="h3">{t("search:page.search.errorTitle")}</h2>
      <p>{t("search:page.search.errorDescription")}</p>
    </div>
  );
}

export function SearchResults(props: {
  status: SearchStatus;
  actions: SearchStoreActions;
  contentTypeProps: DeepReadonly<ContentTypeProps[]>;
  selectedContentType: ExtendedContentType;
  filtersExtended: boolean;
  summariesPaging: SummariesPaging;
  params: URLSearchParams;
}): GaVueComponent {
  const filteredContentTypes = props.contentTypeProps.filter((value) => props.selectedContentType === "ALL" || props.selectedContentType === value.discriminator);

  if (props.status === "ERROR") {
    return <ErrorState />;
  }
  return (
    <>
      {props.contentTypeProps.some((ct) => ct.items.length > 0) || props.status === "LOADING" ? (
        <>
          {filteredContentTypes
            .sort((a, b) => contentTypeOrder[a.discriminator] - contentTypeOrder[b.discriminator])
            .map((contentType) => (
              <div class="mb-4">
                <ContentType
                  contentType={contentType}
                  searchStatus={props.status}
                  selectedContentType={props.selectedContentType}
                  actions={props.actions}
                  filtersExtended={props.filtersExtended}
                  loadMoreSummaries={props.actions.loadMoreSummaries}
                  summariesPaging={props.summariesPaging}
                  params={props.params as URLSearchParams /* Using `DeepReadonly` looses the type information of `URLSearchParams` */}
                />
              </div>
            ))}
        </>
      ) : (
        <div class="py-3">
          <NoResults resetFilters={() => (props.selectedContentType === "ALL" ? props.actions.resetAllFilters() : props.actions.resetAllFilters(props.selectedContentType))} />
        </div>
      )}
    </>
  );
}
