import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { Interactions } from "@/components/ask-getabstract/utils/store";
import type { Store } from "@/common/storeUtils";
import type { Dict } from "@utils/dictUtils";
import type { DeepReadonly } from "vue";
import { GaFeedback } from "@/components/general/GaFeedback";

export function Feedback(props: { feedbackByUuid: DeepReadonly<Dict<boolean>>; feedbackStore: Store<Dict<boolean>>; question: string; questionUuid: string; showMailFeedbackLink?: boolean; border?: boolean }): GaVueComponent {
  const { t } = useI18n();

  function createGiveFeedbackHandler() {
    return (isPositive: boolean) => Interactions.giveFeedback(props.feedbackStore, props.questionUuid, isPositive);
  }

  function feedbackIsPositive(questionUuid: string): boolean | null {
    return props.feedbackByUuid[questionUuid] ?? null;
  }

  return (
    <>
      <GaFeedback
        title={t("search:askGa.feedback.title")}
        value={feedbackIsPositive(props.questionUuid)}
        onChange={createGiveFeedbackHandler()}
        showMailFeedbackLink={props.showMailFeedbackLink}
        mailTitle={`[uuid:${props.questionUuid}]: I want to give Beta feedback`}
        mailBody={`I asked the question: [question:${props.question}].`}
        buttonBorder={props.border}
      />
    </>
  );
}
