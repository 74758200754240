import { type DeepReadonly, defineComponent, type PropType } from "vue";
import { createStore } from "@/components/search/fullSearchStore";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import "./fullSearch.scss";
import { GaChip } from "@/components/general/GaChip";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { FullSearchProps } from "@newgenerated/shared/schema";
import { SearchAskGetAbstractComponent } from "@/components/search/SearchAskGetAbstractComponent";
import { SearchResults } from "@/components/search/SearchResults";
import { search } from "@generated/api/searchApiControllerApi";
import { hasFeature } from "@/common/featureUtils";
import type { ContentTypeProps, ExtendedContentType, SearchStoreActions, SearchStoreState } from "@/components/search/fullSearchStoreTypes";
import { createQuestion, getQuestionAnswers } from "@generated/api/askGetabstractControllerApi";

function SearchContentTabs(props: { selectContentType: (type: ExtendedContentType) => void; selectedContentType: ExtendedContentType; contentTypes: DeepReadonly<ContentTypeProps[]>; loading: boolean }): GaVueComponent {
  const tabOrder: { [content in ContentTypes]: number } = {
    SUMMARY: 1,
    ACTIONABLE: 2,
    CHANNEL: 3,
    CUSTOMPAGE: 4,
  };
  const { t } = useI18n();

  return (
    <div class="d-flex gap-3 flex-wrap">
      <GaChip active={props.selectedContentType === "ALL"} changeHandler={() => props.selectContentType("ALL")} value="ALL" type="radio" name="contentTypeTabs">
        {t("search:page.results.topResults")}
      </GaChip>
      {[...props.contentTypes]
        .sort((a, b) => tabOrder[a.discriminator] - tabOrder[b.discriminator])
        .filter((contentType) => contentType.items.length > 0 || contentType.formFilters.length > 0)
        .map((contentType) => (
          <span>
            <GaChip changeHandler={() => props.selectContentType(contentType.discriminator)} active={contentType.discriminator === props.selectedContentType} value={contentType.discriminator} type="radio" name="contentTypeTabs">
              {contentType.title}
            </GaChip>
          </span>
        ))}
    </div>
  );
}

function getBreakPointMdValue(): number {
  const breakPointValue = getComputedStyle(document.body).getPropertyValue("--ga-breakpoint-lg");
  return parseInt(breakPointValue, 10);
}

function Content(props: { state: DeepReadonly<SearchStoreState>; actions: SearchStoreActions }): GaVueComponent {
  const { t } = useI18n();
  const smallVersion = getBreakPointMdValue() >= window.innerWidth;
  const useFullWidth = props.state.selectedContentType !== "ALL" || props.state.aiState.feature === "NOT_AVAILABLE";

  return (
    <div class="row mt-4 gx-4">
      <h1 class="mb-4">{t("search:page.title")}</h1>
      <div class="mb-5">
        <SearchContentTabs selectContentType={props.actions.selectContentType} selectedContentType={props.state.selectedContentType} contentTypes={props.state.contentTypeProps} loading={props.state.status === "LOADING"} />
      </div>
      <div class={["col-12 order-1 order-lg-0", useFullWidth ? "" : "col-lg-8"]}>
        <SearchResults
          status={props.state.status}
          actions={props.actions}
          contentTypeProps={props.state.contentTypeProps}
          selectedContentType={props.state.selectedContentType}
          filtersExtended={props.state.filtersExtended}
          summariesPaging={props.state.summariesPaging}
          params={props.state.params as URLSearchParams /* Using `DeepReadonly` looses the type information of `URLSearchParams` */}
        />
      </div>
      {props.state.aiState.feature === "AVAILABLE" ? (
        <div class={["col-12 order-0 order-lg-1 mb-5", props.state.selectedContentType === "ALL" ? "col-lg-4" : "d-none"]}>
          <SearchAskGetAbstractComponent state={props.state.aiState.uiState} displayFullAnswer={props.state.aiState.displayFullAnswer} showMore={props.actions.showFullAiAnswer} smallVersion={smallVersion} />
        </div>
      ) : null}
    </div>
  );
}

export const FullSearch = defineComponent({
  props: {
    fullSearchProps: {
      type: Object as PropType<FullSearchProps>,
      required: true,
    },
  },
  setup: (props) => {
    // eslint-disable-next-line no-undef
    const store = createStore(props.fullSearchProps, { search, createQuestion, getQuestionAnswers, hasFeature });
    return () => <Content state={store.state()} actions={store.actions} />;
  },
});
