import { defineComponent, nextTick, type PropType } from "vue";
import type { MIABPageProps, MIABQuestionMetadata, SummaryMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaNumberedList } from "@/components/general/GaNumberedList";
import "./_miab.scss";
import { GaFeedback } from "@/components/general/GaFeedback";
import { useI18n } from "@/i18n/i18nSetup";
import type { Store } from "@/common/storeUtils";
import { createStore } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import { GaSpinner } from "@/components/general/GaSpinner";
import { Header } from "@/components/miab/Header";
import { SummaryCard } from "@/components/cards/SummaryCard";

type SummaryStatus = {
  show: boolean;
  loading: boolean;
};

type MIABPageState = {
  feedback: boolean | null;
  summaryStatus: SummaryStatus;
  showShareModal: boolean;
};

function openSummary(store: Store<SummaryStatus>): void {
  store.set({ show: true, loading: true });
  // eslint-disable-next-line no-restricted-syntax
  void nextTick(() => {
    scrollTo({ top: 0 });
  });
}

function Prepare(props: { summaryMetadata: SummaryMetadata; summaryStore: Store<SummaryStatus> }): GaVueComponent {
  const { t } = useI18n();
  return (
    <>
      <div class="mb-2">
        <span class="badge miab-badge">
          <i class="ico-book-open" />
          {t("miab:page.prepare.badge")}
        </span>
      </div>
      <h2>Prepare</h2>
      <p>
        <strong>Read or listen to the summary individually before the discussion.</strong>
      </p>
      <p>{props.summaryMetadata.descriptionHtml}</p>
      <p>
        <GaButton onClick={() => openSummary(props.summaryStore)}>Open summary</GaButton>
      </p>
    </>
  );
}

function Discuss(props: { questions: MIABQuestionMetadata[] }): GaVueComponent {
  const { t } = useI18n();
  return (
    <>
      <div class="mb-2">
        <span class="badge miab-badge">
          <i class="ico-people" />
          {t("miab:page.discuss.badge")}
        </span>
      </div>
      <h2>Discuss</h2>
      <p>
        <strong>Discuss the following questions as a group.</strong>
      </p>
      <GaNumberedList items={props.questions.map((question) => ({ title: question.title, text: question.question }))} />
    </>
  );
}

function Implement(): GaVueComponent {
  const { t } = useI18n();
  return (
    <div>
      <div class="mb-2">
        <span class="badge miab-badge">
          <i class="ico-calendar-checked" />
          {t("miab:page.implement.badge")}
        </span>
      </div>
      <h2>Implement</h2>
      <p>
        <b>Commit to your actions items individually or with your team.</b>
      </p>
      <ul class="miab-implement__list">
        {t("miab:page.implement.bulletList")
          .split("<br>")
          .map((value) => (
            <li>{value}</li>
          ))}
      </ul>
    </div>
  );
}

function Feedback(props: { feedbackStore: Store<boolean | null> }): GaVueComponent {
  const { t } = useI18n();
  return <GaFeedback title={t("miab:page.feedback.title")} value={props.feedbackStore.get()} onChange={(value) => props.feedbackStore.set(value)} showMailFeedbackLink={true} mailBody={"To be determined"} mailTitle={"To be determined"} />;
}

function RelatedSummaries(props: { relatedSummaries: SummaryMetadata[] }): GaVueComponent {
  const { t } = useI18n();
  return (
    <>
      <h2 class="mb-4">{t("miab:page.relatedContent.title")}</h2>
      <div class="row">
        {props.relatedSummaries.map((summary) => (
          <div class="col-lg-6 col-12 mb-3">
            <SummaryCard summary={summary} />
          </div>
        ))}
      </div>
    </>
  );
}

export function Content(props: { pageProps: MIABPageProps; store: Store<MIABPageState> }): GaVueComponent {
  const link = `/summary/${props.pageProps.summary.dataId}?iframe=true`;
  return (
    <div class="d-flex justify-content-center">
      {!props.store.sub("summaryStatus").get().show ? (
        <div>
          <div class="mb-4 card miab-header">
            <Header summary={props.pageProps.summary} showShareModalStore={props.store.sub("showShareModal")} />
          </div>
          <div class="mb-4 miab-section">
            <Prepare summaryMetadata={props.pageProps.summary} summaryStore={props.store.sub("summaryStatus")} />
          </div>
          <div class="mb-4 miab-section">
            <Discuss questions={props.pageProps.questions} />
          </div>
          <div class="mb-4 miab-section">
            <Implement />
          </div>
          <div class="miab-spacing">
            <Feedback feedbackStore={props.store.sub("feedback")} />
          </div>
          <div class="mb-4 miab-section">
            <RelatedSummaries relatedSummaries={props.pageProps.similarSummaries} />
          </div>
        </div>
      ) : (
        <div class="miab-summary">
          <div class="card miab-section miab-summary__card">
            <div class="miab-summary__title-section">
              <h3 class="miab-summary__title-section-title">Meeting In a Box: Summary</h3>
              <GaButton onClick={() => props.store.sub("summaryStatus").sub("show").set(false)}>
                <i class="ico-close" />
                &nbsp;Close
              </GaButton>
            </div>
            {props.store.sub("summaryStatus").get().loading ? (
              <div class="d-flex justify-content-center mt-5">
                <GaSpinner />
              </div>
            ) : null}
            <iframe class={["miab-summary__iframe", props.store.sub("summaryStatus").get().loading ? "d-none" : ""]} src={link} width="100%" onLoad={() => props.store.sub("summaryStatus").sub("loading").set(false)} />
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Meeting in a box page
 */
export const MIABPage = defineComponent({
  props: {
    miabPageProps: {
      type: Object as PropType<MIABPageProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore<MIABPageState>({
      feedback: null,
      summaryStatus: {
        show: false,
        loading: false,
      },
      showShareModal: false,
    });
    return () => (
      <div class="miab">
        <Content pageProps={props.miabPageProps} store={store} />
      </div>
    );
  },
});
